// Import Styles
import '../sass/styles.scss';

(function () { 

    $(document).ready(function() 
    {
 
    });

    $(window).scroll(function() 
    {
        
    });

})();