// Import Styles
import '../sass/styles.scss';

(function () { 

    $(document).ready(function() 
    {
        $('.js-newsletter-button').on('click', function(e) {
            e.preventDefault();

            $('.js-newsletter-popup').addClass('active');
        });

        $('.js-newsletter-popup__close').on('click', function(e) {
            e.preventDefault();

            $('.js-newsletter-popup').removeClass('active');
        });
    });

    $(window).scroll(function() 
    {
        
    });

})();